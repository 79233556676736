import React from "react";
import { Link } from "react-router-dom";
import "../notFound.css"; // Import styles

const NotFound: React.FC = () => {
  return (
    <div className="notfound-body">
      <div className="container">
        <div className="notfound-icon">⚠️</div>
        <h1>Oops! Page Not Found</h1>
        <p>The page you’re looking for doesn’t exist.</p>
        <Link to="/" className="cta-button">Go Home</Link>
        <div className="footer">© 2025 WaliaChandan.com</div>
      </div>
    </div>
  );
};

export default NotFound;
