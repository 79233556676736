import React from "react";
import { useNavigate } from "react-router-dom";
import "../LandingPage.css"; // Import CSS

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="landing-body">
      <div className="container">
        <div className="welcome-icon">🚀</div>
        <h1>Welcome to WaliaChandan!</h1>
        <p>Your journey to a great experience starts here.</p>
        <button onClick={() => navigate("/dashboard")} className="cta-button">
          Get Started
        </button>
        <div className="footer">© 2025 WaliaChandan.com</div>
      </div>
    </div>
  );
};

export default LandingPage;
