import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createChart, CrosshairMode } from "lightweight-charts";

const emaLines = [
  { key: "fast_current", color: "#2196F3", label: "Fast  Current" },
  { key: "slow_current", color: "#FF5722", label: "Slow  Current" },
  { key: "fast_prev1", color: "#4CAF50", label: "Fast Prev1" },
  { key: "slow_prev1", color: "#FFC107", label: "Slow Prev1" },
  { key: "fast_prev2", color: "#00BCD4", label: "Fast Prev2" },
  { key: "slow_prev2", color: "#E91E63", label: "Slow Prev2" },
];

const MACDDetailedChart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (!location.state?.stockData) {
      navigate("/");
      return;
    }
  
    const { stockData } = location.state;
    const chartContainer = chartContainerRef.current;
    if (!chartContainer) return;
  
    const chart = createChart(chartContainer, {
      width: chartContainer.clientWidth,
      height: 600,
      layout: { background: { color: "#222" }, textColor: "#DDD" },
      grid: { vertLines: { color: "#333" }, horzLines: { color: "#333" } },
      crosshair: { mode: CrosshairMode.Normal },
      timeScale: { secondsVisible: true, timeVisible: true },
    });
  
    // MACD Histogram
    const histogramData = stockData.map((point) => ({
      time: Math.floor(new Date(point.timestamp).getTime() / 1000),
      value: point.fast_current - point.slow_current,
    }));
  
    const histogramSeries = chart.addHistogramSeries({
      color: "#26a69a",
      base: 0,
    });
    histogramSeries.setData(histogramData);
  
    // Zero Line
    const zeroLine = chart.addLineSeries({
      color: "#FFFFFF",
      lineWidth: 1,
      lineStyle: 2,
      priceLineVisible: false,
    });
    zeroLine.setData(histogramData.map(({ time }) => ({ time, value: 0 })));
  
    // EMA Lines
    emaLines.forEach(({ key, color }) => {
      const series = chart.addLineSeries({ color, lineWidth: 2 });
      series.setData(
        stockData.map((point) => ({
          time: Math.floor(new Date(point.timestamp).getTime() / 1000),
          value: point[key],
        }))
      );
    });
  
    chart.timeScale().fitContent();
  
    const handleResize = () => {
      if (chartContainerRef.current && chart) {
        chart.applyOptions({ width: chartContainerRef.current.clientWidth });
      }
    };
  
    window.addEventListener("resize", handleResize);
  
    chartRef.current = chart;
  
    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
  
    // Explicitly avoid infinite loop by omitting chart and refs from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, navigate]);
  
  const zoomChart = (factor) => {
    const chart = chartRef.current;
    if (chart) {
      const timeScale = chart.timeScale();
      const visibleRange = timeScale.getVisibleRange();
      if (visibleRange) {
        const zoomAmount = (visibleRange.to - visibleRange.from) * factor;
        timeScale.setVisibleRange({
          from: visibleRange.from + zoomAmount,
          to: visibleRange.to - zoomAmount,
        });
      }
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen p-4 flex flex-col items-center justify-center">
      <div className="relative w-full max-w-5xl h-[600px] rounded-lg overflow-hidden shadow-xl border border-gray-700">
        {/* Chart Container */}
        <div ref={chartContainerRef} className="w-full h-full bg-[#222]" />

        {/* Legend */}
        <div className="absolute top-4 left-4 bg-gray-800 bg-opacity-90 p-2 rounded text-white text-sm z-50">
          {emaLines.map(({ label, color }) => (
            <div key={label} className="flex items-center gap-2">
              <span
                className="w-4 h-2 rounded-sm"
                style={{ backgroundColor: color }}
              />
              <span>{label}</span>
            </div>
          ))}
        </div>

        {/* Zoom Controls */}
        <div className="absolute bottom-4 left-4 flex flex-col gap-2 z-40">
          <button
            onClick={() => zoomChart(-0.2)}
            className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-full shadow-md text-lg font-bold"
          >
            -
          </button>
          <button
            onClick={() => zoomChart(0.2)}
            className="bg-gray-700 hover:bg-gray-800 text-white px-3 py-1 rounded-full shadow-md text-lg font-bold"
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default MACDDetailedChart;
