import Navbar from "./Navbar";
import Footer from "./Footer";
import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "./features/AuthContext";


export default function Root() {
  const navigate = useNavigate();
  const { isAuthenticated, login, logout } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (window.opener) {
        console.log("🚀 Sending initial URL to parent...");
        window.opener.postMessage({ url: window.location.href }, "http://localhost:3000");
    }

    // ✅ Listen for requests from the parent to check the URL
    window.addEventListener("message", (event) => {
        if (event.origin !== "http://localhost:3000") return;

        if (event.data.action === "check_url") {
            console.log("🔍 Sending updated URL to parent...");
            window.opener.postMessage({ url: window.location.href }, "http://localhost:3000");
        }
        if (event.data.action === "request_token_check") {
            console.log("🔍 Sending updated URL to parent...");
            window.opener.postMessage({ url: window.location.href }, "http://localhost:3000");
        }
        if (event.data.action === "logout") {
            console.log("🔍 Logging out...");
            logout();
        }

        if (event.data.action === "send_url") {
          console.log("🔍 Parent requested updated URL, sending back...");
          window.opener.postMessage({ action: "updated_url", url: window.location.href }, "http://localhost:3000");
      }
        
    });
}, []);

  // if (!isAuthenticated) return null;
  return (
    <>
      {/* <Navbar /> */}
      <div id="detail">
        <Outlet />
      </div>
      {/* <Footer /> */}
    </>
  );
}