import React, { ReactNode } from "react";
import { X } from "lucide-react";

interface PopupModalProps {
    title: string;
    width?: string;
    onClose: () => void;
    children: ReactNode;
}

const PopupModal: React.FC<PopupModalProps> = ({ title, width = "500px", onClose, children }) => {
    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg" style={{ width }}>
                <div className="px-6 py-4 border-b flex justify-between items-center">
                    <h2 className="text-xl font-semibold">{title}</h2>
                    <button className="p-1 rounded-full hover:bg-gray-200" onClick={onClose}>
                        <X className="h-5 w-5 text-gray-500" />
                    </button>
                </div>
                <div className="p-6">{children}</div>
            </div>
        </div>
    );
};

export default PopupModal;