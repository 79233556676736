import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "./features/AuthContext";
import { Eye, EyeOff } from "lucide-react"; // Import icons from lucide-react
import "../Login.css"; // Import styles

export default function Login() {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Password visibility state

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.username) newErrors.username = "Username is required";
    if (!formData.password) newErrors.password = "Password is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        if (formData.username === "admin" && formData.password === "walia@123") {
          localStorage.setItem("userId", "admin");
          setSubmitted(true);
          login();
          setTimeout(() => navigate("/"), 2000);
        } else {
          setErrors({ error: "Invalid Credentials" });
        }
      } catch (error) {
        setErrors({ error: "An error occurred. Please try again." });
      }
      setLoading(false);
    }
  };

  return (
    <div className="login-body">
      <div className="container">
        <div className="login-icon">🔐</div>
        <h1>Login to Your Account</h1>
        <p>Enter your credentials to continue.</p>

        {errors?.error && (
          <p className="error-text">{errors.error}</p>
        )}

        {submitted ? (
          <p className="success-text">Login successful! Redirecting...</p>
        ) : (
          <form onSubmit={handleSubmit} className="login-form">
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Username"
              className="input-field"
            />
            {errors.username && <p className="error-text">{errors.username}</p>}

            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"} // Toggle password visibility
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                className="input-field"
              />
              <button
                type="button"
                className="eye-button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            {errors.password && <p className="error-text">{errors.password}</p>}

            <button type="submit" className="cta-button">
              {loading ? "Logging in..." : "Login"}
            </button>
          </form>
        )}

        <p className="footer-text">
          Don't have an account? <Link to="#" className="link">Sign up</Link>
        </p>

        <div className="footer">© 2025 WaliaChandan.com</div>
      </div>
    </div>
  );
}
