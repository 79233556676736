import { useState, useEffect, useRef } from "react";
import { Check, X, Filter, Plus, Minus, Loader, ArrowLeft } from "lucide-react";
import axios from "axios";
import { Route, useNavigate } from 'react-router-dom'
import AceEditor from "react-ace";
import { toast, ToastContainer } from "react-toastify";  // ✅ Import Toasts
import "react-toastify/dist/ReactToastify.css";  // ✅ Toastify CSS 
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { host } from "../utilities/util";

export default function FilterInterface() {
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);  // Selected filters by user
  const [availableFilters, setAvailableFilters] = useState([]);
  const fetchFiltersCalled = useRef(false);// All filters available from the database
  const [codeInput, setCodeInput] = useState(`def evaluate_condition2():
    """
    Evaluates the given logical condition based on global variable values.
    Returns "Mom-5mtf" if the condition is met, otherwise returns "No".
    """

    if L3_1mtf_3CH_60_130_45_Gp > 30 and L3_1mtf_12_26_9_FL_CUR < 0 and (L3_1mtf_12_26_9_FL_PAST2 - L3_1mtf_12_26_9_FL_PAST1) <= (L3_1mtf_12_26_9_FL_PAST1 - L3_1mtf_12_26_9_FL_CUR):
        return "Mom-5mtf"
    else:
        return "No"
`);
  const [stocks, setStocks] = useState([]);
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [unfilteredStocks, setUnfilteredStocks] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [variableSuggestions, setVariableSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  // ✅ Show Toast Notification
  const showToast = (message, type = "info") => {
    if (type === "success") toast.success(message);
    else if (type === "error") toast.error(message);
    else if (type === "warning") toast.warning(message);
    else toast.info(message);
  };
  // Fetch available filters from the API on component mount
  useEffect(() => {
    if (fetchFiltersCalled.current) return;  // ✅ Skip if already called
    fetchFiltersCalled.current = true;  // ✅ Mark as called

    const fetchFilters = async () => {
      try {
        toast.info("Fetching filters...");  // ✅ Show toast only once
        const response = await axios.get(`${host}get-filters`);
        setAvailableFilters(response.data.filters);
        toast.success("Filters loaded successfully!");
      } catch (error) {
        toast.error("Error fetching filters!");
      }
    };

    const fetchVariableNames = async () => {
      try {
        const response = await axios.get(`${host}get-filter-variables`);
        setVariableSuggestions(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        setVariableSuggestions([]);
      }
    };

    fetchVariableNames();
    fetchFilters();
  }, []);


  const customCompleter = {
    getCompletions: (editor, session, pos, prefix, callback) => {
      if (!prefix) return callback(null, []);  // No suggestions if prefix is empty

      // Ensure variableSuggestions is an array before calling map()
      const suggestions = (Array.isArray(variableSuggestions) ? variableSuggestions : []).map((variable) => ({
        caption: variable,  // Displayed in suggestions list
        value: variable,    // Inserted when selected
        meta: "variable"    // Category shown in the dropdown
      }));

      callback(null, suggestions);
    }
  };


  useEffect(() => {
    const langTools = window.ace.acequire("ace/ext/language_tools");
    langTools.addCompleter(customCompleter);
  }, [variableSuggestions]);

  // Handle adding a custom filter via the editor
  const handleAddFilter = async () => {
    if (!codeInput.trim()) return;
    const functionNameMatch = codeInput.match(/def\s+([a-zA-Z_][a-zA-Z0-9_]*)\s*\(/);
    if (functionNameMatch) {
      const functionName = functionNameMatch[1];

      try {
        showToast("Adding filter...", "info");
        const response = await axios.post(`${host}save-filter`, {
          function_code: codeInput,
          function_name: functionName,
        });

        if (response.status === 201) {
          showToast("Filter added successfully!", "success");
          setAvailableFilters((prev) => [
            ...prev,
            { _id: response.data.filter_id, function_name: functionName, function_code: codeInput },
          ]);
          setCodeInput("");
        }
      } catch (error) {
        showToast(`Error adding filter! ${error}`, "error");
      }
    } else {
      showToast("Invalid function format!", "warning");
    }
  };

  const handleFilterToggle = (filterId, function_code) => {
    setFilters((prev) =>
      prev.includes(filterId)
        ? prev.filter((id) => id !== filterId) // Remove filter from selected filters
        : [...prev, filterId] // Add filter to selected filters
    );

    // Show the function code of the selected filter and ensure only one filter code is displayed
    setSelectedFilter((prev) =>
      prev && prev._id === filterId ? null : { _id: filterId, function_code }
    );
  };

  // Handle removing a filter from both selected filters and available filters
  const handleRemoveFilter = async () => {
    try {
      if (filters.length === 0) return;
      showToast("Removing filters...", "info");

      const response = await axios.delete(`${host}remove-filter`, {
        data: { filters },
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200) {
        showToast("Filter removed successfully!", "success");

        setFilters((prev) => prev.filter((id) => !filters.includes(id)));
        setAvailableFilters((prev) => prev.filter((filter) => !filters.includes(filter._id)));
      }
    } catch (error) {
      showToast("Error removing filter!", "error");
    }
  };


  // Apply the selected filters to stocks
  const applyFilters = async () => {
    if (filters.length === 0) {
      showToast("Please select at least one filter!", "warning");
      return;
    }
    setLoading(true);
    showToast("Applying filters...", "info");

    try {
      const response = await axios.post(`${host}apply-filters`, { filterIds: filters });

      if (!response.data || !response.data.passed_stocks || !response.data.failed_stocks) {
        showToast("Invalid response from server!", "error");
        return;
      }

      setFilteredStocks(response.data.passed_stocks);
      setUnfilteredStocks(response.data.failed_stocks);

      showToast("Filters applied successfully!", "success");
    } catch (error) {
      showToast(`Error applying filters! ${error}`, "error");
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="h-screen bg-gray-50 p-8 grid grid-cols-2 gap-6" style={{ gridTemplateRows: "65% 35%" }}>
      {/* Toast Notification Component */}
      {/* 🔹 Dashboard Link at the Top */}
      <div className="absolute top-1  left-4">
        <button onClick={() => navigate("/dashboard")} className="text-blue-600 hover:text-blue-800 flex items-center space-x-1">
          <ArrowLeft size={18} />
          <span>Back to Dashboard</span>
        </button>
      </div>
      {/* Full-screen loading overlay */}
      {loading && (
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="flex items-center space-x-2 text-white text-lg">
            <Loader className="animate-spin h-6 w-6" />
            <span>Applying filters, please wait...</span>
          </div>
        </div>
      )}


      {/* Available Filters */}
      <div className="bg-white rounded-lg shadow flex flex-col">
        <div className="px-6 py-4 border-b">
          <h2 className="text-xl font-semibold flex items-center">
            <Filter className="mr-2 h-5 w-5" />
            Available Filters
          </h2>
        </div>

        <div className="p-4 space-y-2 flex-1 overflow-y-auto">
          {availableFilters.length > 0 &&
            availableFilters.map((filter) => (
              <div
                key={filter._id}
                className="flex items-center p-2 rounded-md hover:bg-gray-50 cursor-pointer"
                onClick={() => handleFilterToggle(filter._id, filter.function_code)} // Pass filter ID and function code
              >
                <div
                  className={`w-5 h-5 border rounded-md mr-3 flex items-center justify-center ${filters.includes(filter._id) ? "bg-blue-500 border-blue-500" : "bg-white border-gray-300"
                    }`}
                >
                  {filters.includes(filter._id) && <Check className="h-4 w-4 text-white" />}
                </div>
                <span className="text-sm">{filter.function_name}</span>
              </div>
            ))}
          {selectedFilter && (
            <div className="bg-white rounded-lg shadow p-4 mt-4">
              <h3 className="text-xl font-semibold mb-2">Function Code</h3>
              <pre className="bg-gray-100 p-4 rounded-md whitespace-pre-wrap">
                {selectedFilter.function_code}
              </pre>
            </div>
          )}
        </div>

        <div className="p-4 border-t">
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center justify-center"
            onClick={applyFilters}
          >
            <Filter className="mr-2 h-4 w-4" />
            Apply Filters
          </button>
        </div>
      </div>

      {/* Create Filters */}
      <div className="bg-white rounded-lg shadow flex flex-col">
        <div className="px-6 py-4 border-b">
          <h2 className="text-xl font-semibold flex items-center">
            <Plus className="mr-2 h-5 w-5" />
            Create Filters
          </h2>
        </div>
        <div className="p-4 flex-1 flex flex-col h-full">
          <AceEditor
            placeholder="Write your Python code here"
            mode="python"
            theme="github"
            name="pythonEditor"
            onLoad={(editor) => console.log("Editor loaded")}
            onChange={(newCode) => setCodeInput(newCode)}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={codeInput}
            width="100%"
            height="100%"
            setOptions={{
              enableBasicAutocompletion: true, // Enable basic autocomplete
              enableLiveAutocompletion: true,  // Enable live autocomplete as you type
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
            }}
          />

          <div className="flex space-x-2 mt-4">
            <button
              className="px-4 py-2 text-sm bg-green-600 hover:bg-green-700 text-white rounded-md flex items-center"
              onClick={handleAddFilter}
            >
              <Plus className="mr-2 h-4 w-4" />
              Add
            </button>
            <button
              className="px-4 py-2 text-sm bg-red-600 hover:bg-red-700 text-white rounded-md flex items-center"
              onClick={handleRemoveFilter}
            >
              <Minus className="mr-2 h-4 w-4" />
              Remove
            </button>
          </div>
        </div>
      </div>


      {/* Filtered Stocks */}
      <div className="bg-white rounded-lg shadow max-h-64 flex flex-col">
        <div className="px-6 py-4 border-b">
          <h2 className="text-xl font-semibold flex items-center">
            <Check className="mr-2 h-5 w-5" />
            Filtered Stocks
          </h2>
        </div>

        {/* Isolated scrollable container */}
        <div className="p-4 overflow-y-auto">
          <div className="flex flex-wrap gap-2">
            {filteredStocks.map((stock) => (
              <div
                key={stock}
                className="px-3 py-1.5 bg-green-100 rounded-md text-sm max-w-[100px] truncate"
              >
                {stock}
              </div>
            ))}
          </div>
        </div>
      </div>



      {/* Unfiltered Stocks */}
      <div className="bg-white rounded-lg shadow max-h-64 flex flex-col">
        <div className="px-6 py-4 border-b">
          <h2 className="text-xl font-semibold flex items-center">
            <X className="mr-2 h-5 w-5" />
            Stocks That Do Not Match Filter Criteria
          </h2>
        </div>

        <div className="p-4 overflow-y-auto">
          <div className="flex flex-wrap gap-2">
            {unfilteredStocks.map((stock) => (
              <div
                key={stock}
                className="px-3 py-1.5 bg-red-100 rounded-md text-sm max-w-[100px] truncate"
              >
                {stock}
              </div>
            ))}
          </div>
        </div>
      </div>


      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        style={{ zIndex: 9999, position: "fixed" }} // Ensures no layout shift
      />

    </div>
  );
}






function Sidebar({
  onBuy,
  onStartAutoBuy,
  onKillAllOrders,
  onConfigureAutoCancel,
  onSendAlert,
}) {
  return (
    <div className="w-60 bg-white shadow-md h-full p-4 flex flex-col space-y-6">
      <div>
        <h2 className="text-lg font-bold mb-2">Manage Orders</h2>
        <button
          onClick={onBuy}
          className="block w-full text-left px-3 py-2 rounded-md hover:bg-gray-100 text-sm mb-2"
        >
          Buy
        </button>
        <button
          onClick={onStartAutoBuy}
          className="block w-full text-left px-3 py-2 rounded-md hover:bg-gray-100 text-sm"
        >
          Start Auto-Buy
        </button>
      </div>

      <div>
        <h2 className="text-lg font-bold mb-2">Risk Management</h2>
        <button
          onClick={onKillAllOrders}
          className="block w-full text-left px-3 py-2 rounded-md hover:bg-gray-100 text-sm mb-2"
        >
          Kill All Orders
        </button>
        <button
          onClick={onConfigureAutoCancel}
          className="block w-full text-left px-3 py-2 rounded-md hover:bg-gray-100 text-sm"
        >
          Auto-Cancel Trades
        </button>
      </div>

      <div>
        <h2 className="text-lg font-bold mb-2">Alerts</h2>
        <button
          onClick={() => onSendAlert("Test alert via SMS/WhatsApp!")}
          className="block w-full text-left px-3 py-2 rounded-md hover:bg-gray-100 text-sm"
        >
          Send Test Alert
        </button>
      </div>
    </div>
  );
}