import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Root from "./components/Root";
import Login from "./components/Login";
import LandingPage from "./components/LandingPage";
// import { UploadYouTubeScreenshots } from "./components/UploadYoutubeScreenshots";
import MACDChart from "./components/MACDChart";
import { ThemeProvider } from "./components/features/ThemeContext";
import DetailedMACDChart from "./components/DetailedMacdChart";
import FilterInterface from "./components/LogicBuilder";
import NotFound from "./components/ErrorPage";
import ProfessionalInterface from "./components/Dashboard";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path:'/login',
        element:<Login/>
      },
      {
        path: "/macd-detailed-view",
        element: <DetailedMACDChart />
      },
      {
        path: '/logic-builder',
        element: <FilterInterface />
      },
      {
        path: "/dashboard",
        element: <ProfessionalInterface />
      },

    ],
  },
]);

// App component renders RouterProvider with defined router
const App = () => (
  <React.StrictMode>
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

export default App;
