export const IsNull = (value) => {
    if (value === undefined || value === null) {
        return true;
    }

    if (value == "undefined" || value === "null") {
        return true;
    }
    if (value == "" || value == " ") {
        return true;
    }
    return false;


}


export const localStoreObjects = {
    userId: "userId",
    bgmiProfile: "bgmiProfile",
    profilePic: "profilePic",
    step: "step"
}

const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

export const host = isLocalhost ? "http://localhost:5000/api/" : "https://waliachandan.com/api/";

export function convertDate(dateStr) {
    // Convert string to Date object
    const date = new Date(dateStr);

    // Format as "Month Day, Year"
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}