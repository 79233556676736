import React from "react";
import { Loader, Plus, Settings, X } from "lucide-react";

interface Stock {
  tkr: string;
  atkr: string | null;
  inst: string;
  tk: number;
}

interface Order {
  id: number;
  orderId: string;
  stock: string;
  quantity: number;
  time: string;
  orderType: string;
}

interface SidebarProps {
  addedStocks: Stock[];
  stocksData: Stock[];
  loadingAddStocks: boolean;
  handleShowAddStocks: () => void;
  removeStock: (token: number) => void;
  recentOrders: Order[];
  pendingOrders: Order[];
  failedOrders: Order[];
  setShowSettings: (val: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  addedStocks,
  stocksData,
  loadingAddStocks,
  handleShowAddStocks,
  removeStock,
  recentOrders,
  pendingOrders,
  failedOrders,
  setShowSettings,
}) => {
    console.log("assded",stocksData)
    console.log("stkdata",stocksData)
  return (
    <div className="w-64 bg-white border-r">
      <nav className="p-3 space-y-4">
        {/* ➕ Add Stocks Section */}
        <section>
          <button
            className="w-full flex items-center px-4 py-2 text-sm font-medium rounded-md hover:bg-gray-100 mt-4"
            onClick={handleShowAddStocks}
            disabled={loadingAddStocks}
          >
            {loadingAddStocks ? (
              <span className="flex items-center">
                <Loader className="mr-2 h-4 w-4 animate-spin" /> Loading...
              </span>
            ) : (
              <span className="flex items-center">
                <Plus className="mr-2 h-4 w-4" /> Add Stocks -{" "}
                <span className="text-gray-500">
                  Selected ({addedStocks.length})
                </span>
              </span>
            )}
          </button>

          {/* Added Stocks List */}
          <div className="mt-2 max-h-64 overflow-y-auto">
            <div className="space-y-1">
              {addedStocks.length > 0 && stocksData.length > 0 ? (
                addedStocks.map((stock) => {
                //   const stock = stocksData.find((s) => s.tk === stockId);
                  if (!stock) return null;

                  const subtitle = stock.atkr
                    ? `${stock.atkr} • ${stock.inst.slice(0, 3)}`
                    : stock.inst.slice(0, 3);

                  return (
                    <div
                      key={stock.tk}
                      className="flex items-center justify-between p-1 rounded-md hover:bg-gray-50 text-xs"
                    >
                      <div className="leading-tight">
                        <div className="font-medium">{stock.tkr}</div>
                        <div className="text-gray-500">{subtitle}</div>
                      </div>
                      <button
                        className="p-0.5 rounded-full hover:bg-gray-200"
                        onClick={() => removeStock(stock.tk)}
                      >
                        <X className="h-3 w-3 text-gray-500" />
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className="p-2 text-sm text-gray-400">
                  No stocks added yet
                </div>
              )}
            </div>
          </div>
        </section>

        <div className="border-t my-2"></div>

        {/* 🕒 Orders Section */}
        <OrderSection title="Recent Orders" orders={recentOrders} colorClass="text-gray-600" />
        <OrderSection title="Pending Orders" orders={pendingOrders} colorClass="text-yellow-600" />
        <OrderSection title="Failed Orders" orders={failedOrders} colorClass="text-red-600" />

        <div className="border-t my-2"></div>

        {/* ⚙️ Settings Button */}
        <section>
          <button
            className="w-full flex items-center px-4 py-2 text-sm font-medium rounded-md hover:bg-gray-100"
            onClick={() => setShowSettings(true)}
          >
            <Settings className="mr-2 h-4 w-4" />
            Settings
          </button>
        </section>
      </nav>
    </div>
  );
};

// 🌀 Reusable order section
const OrderSection: React.FC<{
  title: string;
  orders: Order[];
  colorClass: string;
}> = ({ title, orders, colorClass }) => (
  <section className="space-y-4 text-[11px] text-gray-800 font-medium">
    <div className="text-gray-500 font-semibold mb-1 uppercase">{title}</div>
    <div className="max-h-32 overflow-y-auto divide-y divide-gray-200 pr-1">
      {orders.length > 0 ? (
        orders.map((order) => (
          <div key={order.id} className="py-1.5">
            <div className="flex justify-between items-center">
              <span className={colorClass}>{order.time}</span>
              <span
                className={`w-5 h-5 rounded-full text-[10px] font-bold flex items-center justify-center ${
                  order.orderType === "Buy"
                    ? "bg-green-100 text-green-700"
                    : "bg-red-100 text-red-600"
                }`}
              >
                {order.orderType === "Buy" ? "B" : "S"}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-900">{order.stock}</span>
              <span className={colorClass}>{order.quantity} Shares</span>
            </div>
          </div>
        ))
      ) : (
        <div className="text-gray-400 italic py-2">
          No {title.toLowerCase()}
        </div>
      )}
    </div>
  </section>
);

export default Sidebar;
